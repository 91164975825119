
import React from "react";
import { Col, Form } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import Section from "../../components/Section";
import Layout from "../../components/Layout";
import Background from "../../components/Background";
import Hero from "../../components/Hero";
import MotifColumn from "../../components/MotifColumn";
import Button from "../../components/Button";

import styles from "./index.module.scss";

function encode(data) {
  return Object.keys(data)
      .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
}

const WhistleBlowerPage = () => {
  return (
      <Layout>
        <Background>
          <Hero>
            <MotifColumn>
              <h1>Whistle Blower Form</h1>
              <p>If you have any concerns or need to report wrongdoing, please fill out the form below.</p>
            </MotifColumn>
          </Hero>
          <Section className={styles.contactSection}>
            <Col xs={12} lg={6}>
              <div className={styles.contactFormWrapper}>
                <Formik
                    validationSchema={yup.object().shape({
                      name: yup.string(),
                      methodOfContact: yup.string(),
                      dateOfIncidents: yup.string().required("Date(s) of incident(s) are required"),
                      descriptionOfWrongdoing: yup.string().required("Description of wrongdoing is required"),
                    })}
                    initialValues={{
                      name: "",
                      methodOfContact: "",
                      dateOfIncidents: "",
                      descriptionOfWrongdoing: "",
                    }}
                    onSubmit={(data) => {
                      fetch("/", {
                        method: "POST",
                        headers: {
                          "Content-Type": "application/x-www-form-urlencoded",
                        },
                        body: encode({
                          "form-name": "whistleblower",
                          ...data,
                        }),
                      })
                          .then(() => {
                            alert("Success!");
                          })
                          .catch((error) => alert(error));
                    }}
                >
                  {({
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      isSubmitting,
                      values,
                      touched,
                      isValid,
                      errors,
                    }) => (
                      <Form
                          data-netlify="true"
                          data-netlify-honeypot="bot-field"
                          name="whistleblower"
                          method="POST"
                          noValidate
                          action="/contact-success"
                      >
                        <input type="hidden" name="bot-field" onChange={handleChange} />
                        <input type="hidden" name="form-name" value="whistleblower" />
                        <Form.Group controlId="formName">
                          <Form.Label>Name (Optional)</Form.Label>
                          <Form.Control
                              type="text"
                              name="name"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.name}
                              className={styles.contactFormInput}
                          />
                        </Form.Group>
                        <Form.Group controlId="formMethodOfContact">
                          <Form.Label>Method of Contact (Optional)</Form.Label>
                          <Form.Control
                              type="text"
                              name="methodOfContact"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.methodOfContact}
                              className={styles.contactFormInput}
                          />
                        </Form.Group>
                        <Form.Group controlId="formDateOfIncidents">
                          <Form.Label>Date(s) of Incident(s)</Form.Label>
                          <Form.Control
                              type="text"
                              name="dateOfIncidents"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.dateOfIncidents}
                              isInvalid={touched.dateOfIncidents && errors.dateOfIncidents}
                              className={styles.contactFormInput}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.dateOfIncidents}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="formDescriptionOfWrongdoing">
                          <Form.Label>Description of Wrongdoing</Form.Label>
                          <Form.Control
                              as="textarea"
                              name="descriptionOfWrongdoing"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.descriptionOfWrongdoing}
                              isInvalid={touched.descriptionOfWrongdoing && errors.descriptionOfWrongdoing}
                              className={styles.contactFormTextarea}
                              style={{ height: 200 }}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.descriptionOfWrongdoing}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Button
                            className={styles.contactFormSubmitButton}
                            type="submit"
                            disabled={isSubmitting}
                        >
                          Submit
                        </Button>
                      </Form>
                  )}
                </Formik>
              </div>
            </Col>
          </Section>
        </Background>
      </Layout>
  );
};

export default WhistleBlowerPage;
